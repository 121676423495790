import React from "react"

import Layout from "../components/layout"
import ProfileImage from "../components/profile-image"
import SEO from "../components/seo"

import Highlight from "../components/common/highlight"
import BodyContainer from "../components/common/body-container"

import styled from "styled-components"
import {
  Spacer,
  SpacerWithLine,
  CenterSection,
  SkillBlockContainer,
  SpacerOnSmall,
} from "."
import ColumnLayout, { Column } from "../components/common/column-layout"
import { TextSection, Paragraph } from "../components/my-work-section"
import SkillBlock from "../components/skill-block"
import ContactBlock from "../components/common/contact-block"
import Footer from "../components/common/footer"
import WorkingPhoto from "../components/working-photo"
import ScreenWidthMediaQueries from "../components/common/screen-width-media-queries"

const ProfileImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  & > div {
    width: 100%;
    max-width: 250px;
  }
  margin-bottom: 16px;
`

const VerticalCenterSection = styled.section`
  display: flex;
  align-items: center;
  height: 100%;
`

const ParagraphWithPaddingRight = styled.p`
  @media ${ScreenWidthMediaQueries.tablet} {
    padding-right: 24px;
  }
`

const ParagraphWithPaddingLeft = styled.p`
  @media ${ScreenWidthMediaQueries.tablet} {
    padding-left: 24px;
  }
`

const About = () => {
  return (
    <Layout section="about">
      <SEO title="About" />
      <BodyContainer>
        <h1>About</h1>
        <Spacer />
        <ColumnLayout>
          <Column flex={1}>
            <ProfileImageContainer>
              <div>
                <ProfileImage isCircle />
              </div>
            </ProfileImageContainer>
            <SpacerOnSmall />
          </Column>
          <Column flex={2}>
            <VerticalCenterSection>
              <TextSection isOnRight>
                <h2>Hey, こんにちは, Olá, Hola!</h2>
                <Paragraph>
                  I'm Mark, a full stack <Highlight>Web Developer</Highlight>{" "}
                  who loves building software, travelling, and learning
                  languages.
                </Paragraph>
                <Paragraph>
                  I originally grew up in Tokyo and I'm based in New York City
                  now. I've been building software for more than half of my life
                  and for the past 7 years I've been a software engineer at
                  Facebook.
                </Paragraph>
              </TextSection>
            </VerticalCenterSection>
          </Column>
        </ColumnLayout>
        <Spacer />

        <h2>A bit more</h2>
        <ColumnLayout>
          <Column flex={1}>
            <ParagraphWithPaddingRight>
              These days I mainly work with Hack (PHP) and Javascript. I
              especially love working with React and the entire web ecosystem
              growing rapidly with it. I've worked in all sorts of areas that
              range from architecting database schemas, API design, and all the
              way to pixels you see on the screen.
            </ParagraphWithPaddingRight>
          </Column>
          <Column flex={1}>
            <ParagraphWithPaddingLeft>
              Outside of work I really like travelling and learning languages
              (the human ones). I've been learning Portuguese and Spanish to a
              point where I'm conversational and I've had amazing times
              travelling to countries in South America like Brazil, Colombia,
              and Peru. I speak English and Japanese fluently, and understand
              German a tiny bit as well.
            </ParagraphWithPaddingLeft>
          </Column>
        </ColumnLayout>
        <Spacer />
        <WorkingPhoto />
        <SpacerWithLine />
        <CenterSection>
          <h2>I love building with</h2>
          <SkillBlockContainer>
            <SkillBlock icon="React" />
            <SkillBlock icon="Javascript" />
            <SkillBlock icon="Typescript" />
            <SkillBlock icon="GraphQL" />
            <SkillBlock icon="Relay" />
            <SkillBlock icon="Hack" />
            <SkillBlock icon="NodeJS" />
            <SkillBlock icon="Gatsby" />
            <SkillBlock icon="Firebase" />
          </SkillBlockContainer>
          <Spacer />
          <h2>I know what I'm doing with</h2>
          <SkillBlockContainer>
            <SkillBlock icon="PHP" />
            <SkillBlock icon="Wordpress" />
            <SkillBlock icon="Apollo" />
            <SkillBlock icon="Flow" />
            <SkillBlock icon="HTML" />
            <SkillBlock icon="CSS" />
            <SkillBlock icon="MySQL" />
            <SkillBlock icon="MongoDB" />
          </SkillBlockContainer>
          <Spacer />
          <h2>I design with</h2>
          <SkillBlockContainer>
            <SkillBlock icon="Figma" />
            <SkillBlock icon="Sketch" />
          </SkillBlockContainer>
          <Spacer />
          <h2>From the past..</h2>
          <SkillBlockContainer>
            <SkillBlock icon="Python" />
            <SkillBlock icon="Django" />
            <SkillBlock icon="Flask" />
            <SkillBlock icon="C++" />
            <SkillBlock icon="IOS" />
            <SkillBlock icon="Android" />
            <SkillBlock icon="Java" />
          </SkillBlockContainer>
          <Spacer />
        </CenterSection>
      </BodyContainer>
    </Layout>
  )
}

export default About
